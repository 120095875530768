<template>
	<div>
		<elt-header title="Webdav" icon="icon-server" intro="Github库 [vhhgx/common] 文件索引" />
		<div class="content">
			<div class="flex cross-center" style="margin-bottom: 24px; color: #ffffff;">
				<div @click="back" style="margin-right: 24px;" class="flex cross-center" v-if="pathArr.length !== 1"> 
					<elt-icon :size="18" icon="icon-arrow_backward" />
					<span style="margin-left: 16px;">返回上一页</span>
				</div>
				<div class="flex" style="">
					<div v-for="item in pathArr" style="padding-right: 24px; color: #ffffff;">
						{{item == '' ? '首页' : item}}
					</div>
				</div>
			</div>

			<div class="item">
				<div class="flex" style="color: #ffffff; margin-bottom: 12px; padding-bottom: 12px; font-size: 18px; border-bottom: 1px solid #858585;">
					<div class="flex-4">文件名</div>
					<div class="flex-1">创建时间</div>
					<div class="flex-1">文件大小</div>
				</div>
				<div v-for="item in list" style="padding: 12px 0; color: #ffffff;" @click="jump(item)" class="flex cross-center">
					<div class="flex-4 flex cross-center">
						<div style="background-color: #ffffff10; padding: 8px; border-radius: 8px;">
							<elt-icon :size="20" :icon="`${item.isFolder ? 'icon-folder' : 'icon-document'}`" style="color: #FFEE51" />
						</div>
						
						<span style="margin-left: 20px; font-size: 16px;">{{item.name}}</span>
					</div>
					<div class="flex-1">
						111
					</div>
					<div class="flex-1">
						222
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EltTitle from '~/elt-title'
import axios from 'axios'
import EltIcon from '~/elt-icon.vue'

export default {
	name: 'Webdav',
	data() {

		return {
			list: [],
			// currentPath: ''
			pathArr: ['']
		}
	},

	components: {
		EltTitle, EltIcon
	},
	mounted() {
		this.getHomeList()
	},
	methods: {
		getList(path) {
			axios.post('https://api.vhhg.site/list', {path: path}).then(res => {
				console.log('res', res.data)

				let filter = []

				res.data.forEach(item => {
					if (item.name.charAt(0) !== '.') {
						filter.push(item)
					}
				})

				this.list = filter
				this.currentPath = path
			})
		},
		getHomeList() {
			this.getList('/')
		},
		// 前进
		jump(item) {
			if(item.isFolder) {
				this.pathArr.push(item.name)

				let path = this.pathArr.join('/')
				console.log('path', path)

				this.getList(path)
			} 
		},
		
		// 后退
		back() {
			if(this.pathArr.length !== 1) {
				this.pathArr.pop()
				let path = this.pathArr.join('/')
				this.getList(path)
			}
			
			// console.log('ddddd', this.pathArr)
			
		}
	}
}
</script>

<style>
.content::-webkit-scrollbar{ display: none; }
.item:first-child { border-top: 1px solid #ffffff; }
</style>